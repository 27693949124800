$primary: #3A3ABF;
$secondary: #E5AC69;
$text-primary: #676767;
$lines: #E2E2E2;
$button-error-hover: #d82f2f;

@import url('https://fonts.googleapis.com/css2?family=Coda:wght@400;800&display=swap');

*{
  font-family: 'Coda', sans-serif;
}

/****************BODY*******************/

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track{
  background: rgba($primary, 0.25);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba($primary, 0.8);
  border-radius: 00px;
}

/**************END-BODY*****************/

/***************NAVBAR******************/

.navCoowise{
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  background: #26266b;
  border-bottom: 1px solid #6C6C6C;
  padding-left: 32px !important;
  padding-right: 32px !important;
  opacity: 1.5;
}

.navbar-nav{
  margin: 0 auto !important;
}

.navbar-light .navbar-nav .nav-link{
  color: #fff !important;
  border-bottom: 2px solid transparent;
  width: fit-content;
}

.nav-coowise{
  justify-content: center;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;

  .nav-link{
    margin-right: 16px;

    &.legal{
      margin-right: 25px;

      &.selected{
        border-bottom: 2px solid $secondary;
      }
    }
  }

  .nav-link:hover{
    border-bottom: 2px solid $secondary;
  }
}

.navbar-light .navbar-toggler-icon{
  background-image: url("../src/components/images/navbar.svg") !important;
}

.navbar-brand{
  padding-top: 0px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;

  .blog-title{
    font-size: 25px;
    margin-left: 10px;
    color: white;
    font-weight: 600;
    letter-spacing: 0.8px;
  }
}

.join-newsletter-container{
  transition: 300ms;
  color: #26266b;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  background-color: white;
  border-radius: 9999px;
  font-family: system-ui;
  font-size: 1.1rem;
  font-weight: 600;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img{
    height: 23px;
    margin-right: 5px;
    margin-top: 2.5px;
  }
}

.join-newsletter-container:hover{
  opacity: 0.85;
}

.language{
  .dropdown-toggle{
    color: #fff;
    padding-top: 1px;
    padding-bottom: 0.5px;
  }

  .dropdown-toggle:hover{
    color: $secondary;
  }

  .dropdown-menu{
    background: #26266b;
    min-width: 6rem;
    box-shadow: 0 0 5px 0px #575757;

    .dropdown-item{
      color: white;
      text-align: center;

      .imgNav{
        width: 50%;
        height: 50%;
        padding-bottom: 2.1px;
        padding-left: 3.5px;
        margin-left: 3px;

        &.uk{
          width: 48%;
        }
      }
    }

    .dropdown-item:hover{
      background: #3d3d97;
    }

    .dropdown-item:focus{
      background: #3d3d97;
    }
  }
}

.transparent-link{
  color: transparent;
  height: 37px;
}

@media (max-width: 991px) {
  .navCoowise{
    padding-top: 15px !important;
    padding-bottom: 23px !important;
  }

  .navbar-brand{
    margin-top: 8px;
  }

  .nav-coowise{
    &.blog{
      display: none;
    }
  }

  .navbar-toggler{
    margin-top: 10px;
    margin-bottom: 8px;
    margin-right: 0px;
  }

  .navbar-light .navbar-nav .nav-link{
    padding-left: 1px;
    padding-right: 1px;
  }

  .language{
    .dropdown-toggle{
      padding-left: 0px;
    }

    .dropdown-menu{
      max-width: 7rem;
    }
  }

  .join-newsletter-container{
    max-width: 100%;
    margin-top: 18px;
  }
}

@media (max-width: 380px) {
  .navCoowise{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/**************END-NAVBAR****************/

/**************GENERAL******************/

.section-coowise{
  padding-top: 100px;
  padding-bottom: 100px;

  .general-row{
    margin-left: -5px;
    margin-right: -5px;
    padding-right: 15px;
    padding-left: 15px;

    .general-div{
      .line-title{
        border-top: 1px solid $primary;
        width: 60px;
        margin-left: 0px;
      }

      .title-section{
        color: $primary;
        font-size: 40px;
        padding-bottom: 20px;
      }
    }
  }
}

.subtitle-coowise{
  font-size: 32px;
  color: $secondary;
  margin-bottom: 15px;
}

.paragraph-coowise{
  font-size: 20px;
  color: $text-primary;
  line-height: 30px;

  &.mobile{
    display: none;
    font-style: italic;
  }

  .mailto-coowise{
    color: $primary;
  }
}

.button-secondary-coowise{
  background: rgba(213,213,213, 0.24) !important;
  color: $secondary !important;
  padding: 12px 32px !important;
  border-color: $secondary !important;
  margin-top: 32px;

  &.margin{
    margin-top: 15px;
    margin-right: 15px;
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.8s ease-in-out;

    &.visible{
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.button-secondary-coowise:hover{
  background-color: $secondary !important;
  color: #fff !important;
}

@media (max-width: 767px) {
  .section-coowise{
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .paragraph-coowise{
    font-size: 18px;
    color: $text-primary;
    line-height: 30px;
  }
}

@media (max-width: 560px) {
  .paragraph-coowise{
    &.mobile{
      display: block;
    }
  }
}

@media (max-width: 410px) {
  .section-coowise{
    .general-row{
      &.contact{
        margin-left: -10px;
        margin-right: -15px;
      }
    }
  }

  .paragraph-coowise{
    font-size: 18px;
    color: $text-primary;
    line-height: 30px;
  }
}

/**************END-GENERAL**************/

/**************HEADER******************/

.header{
  position: relative;
  border-bottom: 1px solid $primary;
  background: #26266b;

  .three-cw-hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .img-carousel{
    width: 100%;
    height: 100vh;
    filter: blur(95px);
  }

  .header-content{
    z-index: 3;
    position: absolute;
    top: 22%;
    left: 10%;
    width: 55%;
    display: flex;
    flex-direction: column;

    h3{
      margin-top: 40px;
      color: white;
      font-size: 60px;
    }

    .typing-demo {
      overflow: hidden;
      letter-spacing: 1px;
      margin-top: 16px;
    }

    .typing-demo::after {
      content: '|';
      animation: blink 1s infinite;
      font-size: 1.3em;
      color: white;
    }

    p{
      color: white;
      font-size: 20px;
      line-height: 30px;
      display: inline;
    }
  }
}

@media (max-width: 767px) {
  .header{
    .header-content {
      margin-top: 0px;
      margin-left: 0%;
      width: 85%;

      h3{
        margin-top: 5px;
        font-size: 50px;
      }

      .typing-demo {
        width: 100%;
      }
    }
  }
}

/**************END-HEADER**************/

/***************SERVICES****************/

#servicios{
  .row-cards{
    flex-wrap: nowrap;
    flex-direction: column;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 42px;
    justify-content: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.8s ease-in-out;

    &.visible{
      opacity: 1;
      transform: translateY(0);
    }

    .sub-row-cards{
      display: flex;
      flex-direction: row;
      margin-right: 15px;
      margin-left: 15px;

      .service-card{
        width: 50%;
        background-color: white;
        display: flex;
        flex-direction: row;
        border:none;
        border-top: 4px solid $primary;
        border-radius: 10px;
        box-shadow: 0px 10px 20px 5px #f1f1f1;
        border-radius: 10px;
        margin-bottom: 50px;
        margin-right: 25px;
        cursor: pointer;

        .service-card-content{
          width: 66.67%;

          .service-card-header{
            padding: 25px;
            padding-right: 0px;
            padding-bottom: 10px;
            margin-bottom: 0px;
            background-color: white;
            border-bottom: none;
            
            .service-card-title{
              font-size: 21px;
              color: black;
              font-weight: 600;
              text-align: left;
              white-space: nowrap;
            }
          }

          .service-card-body{
            display: flex;
            flex-direction: column;
            font-size: 16px;
            padding: 1.25rem;
            padding-right: 0px;

            .sub-body{
              margin-bottom: 15px;
              padding-left: 10px;

              h1{
                font-size: 17px;
                color: $primary;
              }

              .text{
                font-size: 16px;
                color: #676767;
                margin-bottom: 0px;
              }

              .long-text{
                display: none;
                font-size: 16px;
                color: #676767;
                margin-bottom: 0px;
              }
            }

            .sub-body:last-child{
              margin-bottom: 0px;
            }
          }
        }

        .service-card-image{        
          width: 33.33%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          img{
            width: 115px;
          }

          .cloud{
            width: 125px;
          }
        }
      }

      .service-card:last-child{
        margin-left: 25px;
        margin-right: 0px;
      }

      .service-card:hover {
        z-index: 10;
        transform: scale(1.05);
        transition: 1s;

        .service-card-content {
          width: 100%;
          padding: 10px;
          .service-card-body {
            .sub-body {
              .text {
                display: none;
              }

              .long-text {
                display: block;
              }
            }
          }
        }

        .service-card-image{
          display: none;
        } 
      }
    }
  } 
}

@media (max-width: 1200px) {
  #servicios{
    .row-cards{
      .sub-row-cards{
        margin-left: 0px;
        margin-right: 0px;

        .service-card{
          margin-right: 10px;

          .service-card-content{
            width: 70%;
          }

          .service-card-image{        
            width: 30%;

            img{
              width: 110px;
            }
  
            .cloud{
              width: 120px;
            }
          }
        }

        .service-card:last-child{
          margin-right: 0px;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #servicios{
    .row-cards{
      .sub-row-cards{
        flex-direction: column;
        margin-right: 50px;
        margin-left: 50px;
        
        .service-card{
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;

          .service-card-content{
            width: 65%;
          }

          .service-card-image{        
            width: 35%;

            img{
              width: 125px;
            }

            .cloud{
              width: 135px;
            }
          }
        }
        
        .service-card:last-child{
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #servicios{
    .row-cards{
      margin-top: 40px;
      .sub-row-cards{
        margin-right: 25px;
        margin-left: 25px;
        
        .service-card{
          .service-card-image{
            img{
              width: 110px;
            }
  
            .cloud{
              width: 120px;
            }
          }
        }
      }
    } 
  }
}

@media (max-width: 560px) {
  #servicios{
    .row-cards{
      .sub-row-cards{
        margin-right: 10px;
        margin-left: 10px;
        
        .service-card{
          .service-card-content{
            width: 100%;

            .service-card-header{
              .service-card-title{
                white-space: normal;
              }
            }
          }

          .service-card-image{        
            display: none;
          }
        }

        .service-card:hover {
          z-index: 10;
          transform: none;
          transition: 1s;
  
          .service-card-content {
            padding: 0px;
            .service-card-body {
              .sub-body {
                .text {
                  display: block;
                }
  
                .long-text {
                  display: none;
                }
              }
            }
          }
  
          .service-card-image{
            display: none;
          } 
        }
      }
    } 
  }
}

/**************END-SERVICES*************/

/***************SOLUTIONS****************/

#soluciones{
  .row-soluciones{
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 32px;
    flex-wrap: nowrap;
    flex-direction: row;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.8s ease-in-out;

    &.visible{
      opacity: 1;
      transform: translateY(0);
    }

    .img-soluciones{
      width: 100%;
    }

    .col-right{
      text-align: right;

      .div-description-solution{
        text-align: left;
        margin-bottom: 30px;
      }
    }
  } 
}

@media (max-width: 991px) {
  #soluciones{
    .row-soluciones{
      flex-direction: column;
    }
  }
}

@media (max-width: 767px) {
  #soluciones{
    .row-soluciones{
      .col-right{
        .div-description-solution{
          text-align: left;
        }
      }
    }
  }
}

/**************END-SOLUTIONS*************/

/*************CERTIFICATIONS*************/

#certificaciones{
  .row-certificaciones{
    margin-top: 50px;
    margin-left: -5px;
    margin-right: -5px;
    overflow-x: hidden;
  
    .col-certificaciones{
      display: flex;
      justify-content: center;
      opacity: 0;
      transform: translateX(20px);
      transition: all 0.8s ease-in-out;

      &.first{
        transform: translateX(-20px);

        &.visible{
          opacity: 1;
          transform: translateX(0);
        }
      }

      &.visible{
        opacity: 1;
        transform: translateX(0);
      }
  
      img{
        width: 90%;
  
        &.first{
          border-radius: calc(1.25rem - 1px);
          border: 1px solid #d1d1d1;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #certificaciones{
    .row-certificaciones{
      .col-certificaciones{
          margin-top: 24px;
      }
    }
  }
}

@media (min-width: 767px) {
  #certificaciones{
    .row-certificaciones{
      .col-certificaciones{
        &.first{
          margin-bottom: 20px;
        }

        img{
          width: 70%;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #certificaciones{
    .row-certificaciones{
      .col-certificaciones{

        &.first{
          margin-bottom: 0px;
        }
    
        img{
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  #certificaciones{
    .row-certificaciones{
      .col-certificaciones{
    
        img{
          width: 90%;
        }
      }
    }
  }
}

/***********END-CERTIFICATIONS***********/

/***************CONTACTUS****************/

#contacto{
  .contact-row{
    padding-bottom: 30px;
    padding-top: 30px;
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 15px;
    padding-right: 15px;
    flex-wrap: nowrap;
    flex-direction: column;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.8s ease-in-out;

    &.visible{
      opacity: 1;
      transform: translateY(0);
    }
  
    .badges-group{
      margin-top: 5px;
      margin-bottom: 18px;
      line-height: 2.7;
  
      .badge{
        padding: 5px 8px 5px 8px;
        border-radius: 3rem ;
        text-align: center;
        border: 1px solid #E5AC69;
        background-color: floralwhite;
        margin-right: 15px;
  
        &.active{
          color: #e5ac69;
          background-color: #e5ac6933;
        }
      }
  
      .badge:hover{
        color: #e5ac69;
        background-color: #e5ac6933;
        cursor: pointer;
      }
    }
  
    .form-control{
      background: rgba(213,213,213, 0.1) !important;
      color: #676767 !important;
      font-size: 16px !important;
      border: 1px solid #E2E2E2;
      border-color: #E5AC69;
    }
  
    .form-control:focus{
      box-shadow: none !important;
      border-bottom: 2px solid $primary !important;
      color: $primary !important;
    }
  
    .form-control:hover{
      box-shadow: none !important;
      border-bottom: 2px solid $primary !important;
      color: $primary !important;
    }
  
    .phoneInputDiv{
      background: rgba(213,213,213, 0.1) !important;
      color: #676767 !important;
      font-size: 16px !important;
      border: 1px solid #E2E2E2;
      border-color: #E5AC69;
      border-radius: 3px;
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.070rem 0.50rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

      .PhoneInput{
        height: 30px;
        .PhoneInputInput{
          border-color:#E5AC69;
          border-width: 0px;
          font-size: 15px;
        }
    
        .PhoneInputInput:focus{
          outline: none;
          color: $primary;
        }
    
        .PhoneInputInput:hover{
          outline: none;
          color: $primary;
        }
      }
    }

    .phoneInputDiv:focus{
      box-shadow: none !important;
      border-bottom: 2px solid $primary !important;
      color: $primary !important;
    }
  
    .phoneInputDiv:hover{
      color: $primary !important;
      box-shadow: none !important;
      border-bottom: 2px solid $primary !important;
    }

    .labelIncompleteData{
      font-size: 0.9rem;
      color: $button-error-hover;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 767px) {
  #contacto{
    .contact-row{
      .contactus{
        display: block !important;
      }
    }
  }
}

@media (max-width: 550px) {
  #contacto{
    .contact-row{
      .badges-group{
        text-align: center;
      }
    }
  }
}

/*************END-CONTACTUS**************/

/****************FOOTER*****************/

.section-footer{
  background: #26266b;
  padding-top: 50px;
  padding-bottom: 30px;

  .footer-row{
    margin-left: -7.5px;
    margin-right: -7.5px;
    flex-wrap: nowrap;
    flex-direction: row;

    .col-images{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
  
      .logo-coowise{
        margin-bottom: 15px;
      }
  
      .ms-partner{
        max-width: 80px;
      }
    }

    .footer-col{
      .title-footer{
        color: $secondary;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 12px;
      }

      .div-servicio-footer{
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #fff;

        &.aboutus{
          border-bottom: none;
          padding-bottom: 0px;
        }

        .description-contact-footer{
          color: #fff;
          margin-left: 8px;
          font-size: 12px;
      
          a{
            color: #fff;
          }
        }

        .title-servicio-footer{
          font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
          color: #fff;
          font-size: 12px;
    
          &.legal{
            cursor: pointer;
          }
    
          &.legal:hover{
            text-decoration: none;
            color: $secondary;
          }
    
          &.notlegal:hover{
            text-decoration: none;
          }
        }
      }
    }

    .col-copy-right{
      text-align: center;
  
      .line-footer{
        border-top: 1px solid #fff;
        margin-top: 32px;
        margin-bottom: 32px;
      }
  
      .copy-right{
        color: #fff;
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 991px) {
  .section-footer{
    .footer-row{
      flex-direction: column;

      .footer-col{
        margin-top: 24px;
      }
    }
  }
}

/**************END-FOOTER***************/

/**********COOKIES-POLICY***************/

.privacy-section{
  padding-top: 100px;
  padding-bottom: 100px;

  hr{
      margin-top: 15px !important;
      margin-bottom: 15px !important;
  }

  h1{
      margin: 0px;
  }

  h2{
      margin: 0px;
  }

  h4{
      margin: 0px;
      margin-bottom: 35px;
  }

  .privacy-row{
      margin-top: 40px;
  }

  ul{
      list-style-type: disc;
      padding-inline-start: 40px;
  }

  ol{
      list-style-type: lower-alpha;
      padding-inline-start: 40px;
  }
}

.line-title{
border-top: 1px solid $primary;
width: 60px;
margin-left: 0px;
}

.title-cookies-policy{
  color: $primary;
  font-size: 50px;
  padding-bottom: 20px;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 5px;
  font-weight: 500;
  line-height: 1.2;
}

.title-section-privacy-policy{
  color: $primary;
  font-size: 40px;
  padding-bottom: 20px;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 5px;
  font-weight: 500;
  line-height: 1.2;
}

.text-below-title{
  font-size: 18px;
  color: #000000;
}

.summary-item{
  font-size: 20px;
  color: #000000;
  line-height: 30px;
  margin-bottom: 5px;

  &.privacy{
      margin-bottom: 15px;
  }
}

.privacy-policy-paragraph{
  font-size: 20px;
  color: #000000;
  line-height: 30px;
  margin-bottom: 20px;

  b{
    font-size: 16px;
  }
}

.policy-subtitle{
  font-size: 28px;
  color: $primary;
  margin-bottom: 30px !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 5px;
  font-weight: 500;
  line-height: 1.2;
}

.sub-columns{
  margin-top: 50px;

  &.privacy{
      margin-top: 20px;

      &.second{
          margin-top: 40px
      }
  }
}

.list-margin{
  margin-bottom: 20px;
}

.privacy-policy-table-container{
  overflow-x: auto;
  margin-bottom: 35px;

  &.blog{
    margin-top: 30px;
  }

  .privacy-policy-table {
    width: 100%;
    max-width: 100%;
    border: 1px solid #EFEFEF;
    
    .tr-head{
      background-color: $primary;

      &.blog{
        background-color: #26266b;
      }
    }

    .thead-td-table{
      padding: 15px;
    }

    .tbody-td-table{
      padding: 15px;
      white-space: nowrap;
    }
    
    .thead-text{
      font-weight: 500;
      font-size: 18px;
      color: white;
      line-height: 30px;
    }
    
    .tbody-text{
      font-size: 16px;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 767px) {
  .privacy-section{
    padding-bottom: 50px;
  }
}

/***********END-COOKIES-POLICY**********/

/******************BLOG*****************/

.blog-global-screen{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.blog-section{
  padding-top: 100px;
  padding-bottom: 80px;
  background: linear-gradient(95deg, rgb(10, 61, 74), rgb(46, 18, 75));
  flex: 1 0;

  .blog-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: 4rem;
    padding-right: 4rem;
    width: 100%;

    .blog-header{
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .blog-search-bar-container{
        display: grid;
        flex-direction: row;
        position: relative;
        
        .blog-search-bar{
          transition-duration: 100ms;
          padding-right: 2.5rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 1rem;
          --tw-bg-opacity: 0.5;
          border-radius: 9999px;
          width: 256px;
          font-family: 'Arial', sans-serif;
          font-size: 15.4px;
          border: none;
          background-color: rgba(31, 41, 55, 0.5);
          height: 42px;
          color: #dddddd;
          outline: none;
        }

        .blog-search-bar::placeholder{
          color: #a0a0a0;
        }

        .blog-search-bar:focus{
          outline: 2.2px solid rgb(29, 78, 216);
          transition: none;
        }

        .blog-search-bar::before{
          outline: none;
        }

        .magnifying-glass{
          position: absolute;
          display: block;
          height: 20px;
          top: 10px;
          right: 10px;
        }
      }

      .blog-category-buttons-container{
        display: flex;
        flex-direction: row;

        .category-button{
          color: white;
          background-color: rgb(38, 38, 107);
          border-radius: 9999px;
          border: none;
          font-family: 'Arial', sans-serif;
          padding-top: 3px !important;
          padding-bottom: 3px !important;
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .category-button:hover{
          background-color: rgb(29, 78, 216);
        }
      }
    }

    .blog-cards-section{
      padding-top: 2.5rem;
      padding-bottom: 0.5rem;

      h1{
        font-family: 'Arial', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 2.5rem;
        color: white;
        text-align: center;
        margin-bottom: 5rem;
      }

      .blog-cards-container{
        transition-duration: 300ms;

        .blog-card{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          opacity: 0.9;
          border-radius: 2.5rem;
          /* border-top-left-radius: 0px;
          border-top-right-radius: 0px; */
          background-color: white;
          
          .thumbnails{
            width: 100%; /* Que ocupe todo el ancho de la tarjeta */
            height: 160px; /* Ajusta la altura automáticamente para mantener la proporción */
            object-fit: cover;
            background-color: transparent;
            object-position: 50% 25%;
            border-bottom: 1px solid #1e40af34;
            object-position: 50% 36%;
          }

          .blog-card-body-header{
            background-color: white;
            padding-top: 1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.75rem;

            label{
              font-family: system-ui;
              font-weight: 600;
              font-size: 0.975rem;
              line-height: 1.25rem;
              color: #2563eb;
            }

            .author-container{
              display: flex;
              flex-direction: row;
              color: #6b7280;

              img{
                height: 18px;
                margin-right: 5px;
              }

              p{
                font-family: system-ui;
                font-size: 0.975rem;
                line-height: 1.25rem;
              }
            }

            .stage{
              color: #1e40af;
              font-weight: 500;
              font-size: 0.875rem;
              line-height: 1.25rem;
              padding-top: 0.125rem;
              padding-bottom: 0.125rem;
              padding-left: 0.625rem;
              padding-right: 0.625rem;
              background-color: #dbeafe;
              border-radius: 0.25rem;
              font-family: system-ui;
              white-space: nowrap;
            }
          }

          h3{
            color: #1e40af;
            font-size: 1.35rem;
            line-height: 1.75rem;
            font-family: system-ui;
            margin-bottom: 0.75rem;
            background-color: white;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }

          .card-paragraph{
            color: #4b5563;
            font-family: system-ui;
            margin-bottom: 1.25rem;
            font-size: 1.19rem;
            background-color: white;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }

          .blog-card-footer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem;
            
            label{
              font-family: system-ui;
              font-size: 1rem;
              margin-bottom: 0px;
              color: #6b7280;
            }

            .footer-container{
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;

              label{
                margin-right: 5px;
                font-size: 1.175rem;
                cursor: pointer;
                color: #2563eb;
              }

              img{
                height: 18px;
                margin-top: 4px;
                cursor: pointer;
              }
            }
          }
        }

        .blog-card:hover{
          z-index: 3;
          transform: scale(1.05);
          transition: 0.8s;
        }
      }
    }

    .blog-footer{
      display: none;
      margin-top: 5rem;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      i.icon{
        font-size: 1.6rem;
        color: white;
        margin-bottom: 5px;
      }

      i.icon:hover{
        color: #2563eb;
      }

      i.icon:first-child{
        margin-right: 15px;
      }

      i.icon:last-child{
        margin-left: 5px;
      }

      button{
        flex: 1;
        max-width: 30px;
        padding: 6px 12px;
        background-color: #26266b;
        background-color: white;
        color: #d1d1d1;
        color: #26266b;
        border: none;
        border-radius: 4px;
        transition: 0.2s;
      }

      button:last-child{
        margin-right: 0px;
      }

      button:hover{
        background-color: #2563eb;
        color: white;
      }

      .selected{
        background-color: #2563eb;
        color: white;
      }
    }
  }
}

.article-section{
  padding-top: 100px;
  padding-bottom: 100px;
  background: linear-gradient(95deg, rgb(10, 61, 74), rgb(46, 18, 75));
  flex: 1 0;

  .article-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 100%;

    .article-header{
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: left;

      .back-button-container{
        background-color: rgb(38, 38, 107);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 9999px;
        padding: 0.5rem 1rem;
        cursor: pointer;
        margin-bottom: 2rem;

        img{
          height: 25px;
          margin-right: 5px;
          margin-top: 2px;
          margin-bottom: 2px;
        }

        label{
          color: white;
          font-family: system-ui;
          font-size: 1.125rem;
          margin-bottom: 2px;
          cursor: pointer;
        }
      }

      .back-button-container:hover{
        background-color: rgb(29, 78, 216);
        z-index: 10;
        transform: scale(1.05);
        transition: 0.8s;
      }
    }

    .thumbnails{
      width: 100%;
      height: 130px;
      object-fit: cover;
      background-color: transparent;
      object-position: 50% 25%;
      border-bottom: 1px solid #1e40af;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    .article{
      padding: 3rem 3.5rem;
      opacity: 0.9;
      background-color: white;
      border-radius: 0.5rem;

      h1{
        font-family: system-ui;
        font-weight: 700;
        color: #1e40af;
        font-size: 2.25rem;
        line-height: 2.5rem;
        margin-bottom: 1.2rem;
      }

      .author-date-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 2.5rem;

        .general-container{
          display: flex;
          flex-direction: row;
          align-items: center;
          
          img{
            height: 18px;
            margin-right: 0.5rem;
            margin-bottom: 0px;
          }

          label{
            margin-bottom: 0px;
            font-family: system-ui;
            font-size: 1.1rem;
            color: #6b7280;
          }
        }

        .general-container:first-child{
          margin-right: 1.5rem;
        }
      }

      .article-div{
        padding-left: 10px;
        padding-right: 10px;

        .column-section{
          display: flex;
          flex-direction: column;
          justify-content:space-between;
          gap: 2rem;
        }

        .step{
          padding-left: 10px;
          padding-right: 25px;

          &.pr-10{
            padding-right: 10px;
          }

          .blog-table{
            display: none;
            flex-direction: column;
            gap: 1.8rem;
            margin-top: 30px;
            margin-bottom: 35px;

            .blog-table-row{
              font-weight: 500;
              font-size: 24px;
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);

              .blog-table-row-header{
                background-color: #f9fafb;
                padding: 0.5rem 1rem;
                background-color: #26266b;
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;

                h3{
                  font-size: 16px;
                  font-family: system-ui;
                  margin: 0;
                  color: white;
                }
              }

              .blog-table-column{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0.5rem 0px;
                border-bottom: 1px solid #e5e7eb;
                padding: 1rem 1rem;

                .tool{
                  color: #6b7280;
                  font-size: 15px;
                  font-weight: 400;
                  font-family: system-ui;
                  margin: 0px;
                  margin-bottom: 2px;
                }

                .description{
                  font-size: 16px;
                  font-family: system-ui;
                  font-weight: 500;
                }
              }
            }
          }

          .two-column-step{
            display: flex;
            flex-direction: row;
            margin-top: 35px;
            gap: 60px;
            align-items: center;

            .step-line{
              display: flex;
              flex-direction: column;
              min-width: 550px;
              align-items: center;

              .relative{
                display: flex;
                flex-direction: column;
                align-items: center;

                .gradient{
                  padding: 0.14rem;
                  background: linear-gradient(to right, #3b82f6, #9333ea);
                  border-radius: 0.6rem;
                  max-width: 425px;

                  .step-card{
                    background-color: white;
                    padding: 1.5rem;
                    border-radius: 0.5rem;
                    border-width: 0px;
                    border-style: solid;
                    border-color: white;

                    .step-card-container{
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                      gap: 1rem;
                      
                      i{
                        margin-bottom: 6px;
                        font-size: 1.25rem;
                        color: #2563eb;
                        font-weight: 400;
                      }

                      h3{
                        margin: 0px;
                        font-size: 1.25rem;
                        line-height: 1.75rem;
                        font-weight: 500;
                        color:#1f2937;
                        color: #1e40af;
                        text-align: center;

                      }
                    }
                  }
                }

                .gradient:hover{
                  z-index: 3;
                  transform: scale(1.05);
                  transition: 0.35s;
                }
              }

              .line{
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                background: linear-gradient(to right, #3b82f6, #9333ea);
                height: 1rem;
                width: 0.125rem;
                justify-content: center;
              }
            }
          }
          
          .blog-alternative-table-container{
            display: flex;
            flex-direction: row;
            gap:40px;
            justify-content: center;
            
            .blog-alternative-table{
              box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
              padding: 1.5rem;
              margin-bottom: 1.5rem;
              margin-top: 1.5rem;
              max-width: 655px;
              min-width: 655px;
              height: fit-content;
  
              .alternative-table-header{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 0.58rem;
                margin-bottom: 1.5rem;
  
                img{
                  height: 2.45rem;
                  width: 2.45rem;

                  &.plugin{
                    height: 1.85rem;
                    width: 1.85rem;
                  }
                }
  
                h2{
                  font-weight: 600;
                  font-size: 1.25rem;
                  line-height: 1.75rem;
                  margin: 0;
                }
              }
  
              .overflow-x-auto{
                overflow-x: auto;
  
                table{
                  min-width: 100%;
                  text-indent: 0;
                  border-color: inherit;
                  border-collapse: collapse;
  
                  p{
                    font-size: 1.10rem;
                    font-family: system-ui;
                    white-space: nowrap;
                    margin-top: 15px;

                    &.mt-15{
                      margin-top: 15px;
                      margin-bottom: 10px;
                    }
                  }
                  
                  thead{
                    tr{
                      th{
                        color: #6b7280;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 0.75rem;
                        line-height: 1rem;
                        text-align: left;
                        padding-top: 0.75rem;
                        padding-bottom: 0.75rem;
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                        font-family: system-ui;
                        
                      }
                    }
                  }
                  
                  tbody{
                    tr{
                      border-top: 1px solid #e5e7eb;
  
                      &.nb{
                        border-top: none;
                      }
  
                      td{
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                        white-space: inherit;
                        font-family: system-ui;
                      }
                    }
                  }
                }
              }
            }
          }

          .blog-milvus-table-container{
            margin-top: 50px;
            margin-bottom: 65px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;

            button{
              box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
              color: white;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              background-color: #2e124b;
              border-radius: 9999px;
              border: none;
              cursor: default;
              position: relative;
              left: 50%;
              margin-right: 0px;

              &.fields{
                background-color: #0a3d4a;
                left: 0%;
              }
            }

            .button-table{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 25px;

              table{
                width: 100%;
                min-width: 650px;
                text-indent: 0;
                border-color: inherit;
                border-collapse: collapse;
                box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
                overflow: hidden;
                border-radius: 0.5rem;

                thead{
                  background-color: #f3f4f6;

                  th{
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    font-weight: 600;
                    color: #374151;
                    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                  }
                }

                tbody{
                  tr{
                    border-bottom: 1px solid #e5e7eb;
                    border-top: 1px solid #e5e7eb;

                    &.grey{
                      background-color: #f9fafb;
                    }

                    td{
                      font-size: 0.875rem;
                      line-height: 1.25rem;
                      padding-left: 1.5rem;
                      padding-right: 1.5rem;
                      padding-top: 1rem;
                      padding-bottom: 1rem;
                      font-size: 0.875rem;
                      line-height: 1.25rem;
                      font-weight: 600;
                      color: #374151;
                      font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                    }

                    td:last-child{
                      min-width: 190px;
                    }
                  }

                  tr:last-child{
                    border-bottom: none;
                  }
                }
              }
            }
          }

          .video-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
        }

        .last-step{
          padding-left: 10px;
          padding-right: 25px;
          margin-bottom: 50px;
        }

        p{
          font-size: 1.175rem;
          font-family: system-ui;

          span{
            background-color: #f1f1f1;
            padding: 3.25px 3.75px;
            border-radius: 5px;
            font-size: 1rem;
            font-family: system-ui;
          }

          .bold{
            font-weight: 500;
            font-family: system-ui;
            background-color: transparent;
            padding: 0px;
            border-radius: 0px;
            font-size: 1.25rem;
            padding-right: 6px;
          }

          .fw600{
            font-weight: 600;
            font-size: 1.175rem;
            font-family: system-ui;
            background-color: transparent;
            padding: 0px;
          }

          a{
            font-family: system-ui;
          }

          &.mb-15{
            margin-bottom: 20px;
          }

          &.mt-20{
            margin-top: 20px;
          }

          &.mb-25{
            margin-bottom: 25px;
          }

          &.note{
            border-left: 4px solid #0066cc;
            background-color: #f8f9fa;
            padding: 1rem;
            border-radius: 0 4px 4px 0;

            &.important{
              border-left: 4px solid rgb(252, 252, 34);
            }

            .fw600{
              font-weight: 600;
              font-size: 1.175rem;
              font-family: system-ui;
              background-color: transparent;
              padding: 0px;
            }
          }
        }

        .mb-05{
          margin-bottom: 0.5rem;
        }

        h2{
          font-family: system-ui;
          font-weight: 600;
          color: #1e40af;
          font-size: 1.3rem;
          line-height: 2.5rem;
          margin-bottom: 0.8rem;
        }

        .article-subtitle{
          margin-bottom: -5px;
          font-size: 1.15rem;
        }

        ol {
          list-style-type: decimal; /* Muestra números */
        }

        .custom-bullets{
          padding-left: 20px;

          &.numbers{
            counter-reset: item;

            li{
              counter-increment: item
            }

            li::marker {
              content: counter(item) ".";
              font-size: 15px;
            }

            .two{
              margin-top: 25px;

              p{
                margin: 0;
                margin-top: 5px;
              }

              p:first-child{
                margin-top: 0px;
              }

              .list-title{
                font-size: 17px;
                font-weight: 500;
              }

              .bull{
                color: #1e40af;
                padding: 0;
                background-color: transparent;
                margin-right: 5px;
              }
            }

            ul{
              li::marker {
                content: '•'; /* Símbolo del bullet */
                color: #1e40af; /* Cambia el color del bullet */
                font-size: 20px; /* Tamaño del bullet */
                position: absolute; /* Posiciona el bullet de forma independiente */
                left: 0; /* Posiciona el bullet a la izquierda del item */
                top: 0; /* Alinea verticalmente el bullet al inicio del texto */
              }
            }
          }

          li{
            font-size: 1.05rem;
            font-family: system-ui;
            padding-left: 10px;
            margin-bottom: 1px;

            span{
              font-size: 1.05rem;
              font-family: system-ui;
              font-weight: 500;
            }

            .pd{
              font-weight: 500;
              font-size: 1rem;
              background-color: #f1f1f1;
              padding: 1.75px 3.75px;
              border-radius: 5px;
            }

            a{
              font-family: system-ui;
            }
          }

          li::marker {
            content: '•'; /* Símbolo del bullet */
            color: #1e40af; /* Cambia el color del bullet */
            font-size: 20px; /* Tamaño del bullet */
            position: absolute; /* Posiciona el bullet de forma independiente */
            left: 0; /* Posiciona el bullet a la izquierda del item */
            top: 0; /* Alinea verticalmente el bullet al inicio del texto */
          }
        }

        .bash{
          display: flex;
          flex-direction: column;
          border-radius: 5px;
          background-color: #212529;
          max-width: 100%;
          margin-top: 20px;
          margin-bottom: 20px;

          &.mt-10{
            margin-top: 10px;
          }

          .bash-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            color: #b4b4b4;
            background-color: #353740;
            padding: 0.5rem 1rem;

            label{
              font-size: 12px;
              margin:0px;
              font-family: system-ui;
            }

            button{
              padding: 5px;
              color: #b4b4b4;
              background-color: transparent;
              display: flex;
              flex-direction: row;
              align-items: center;

              p{
                font-size: 12px;
                margin-bottom: 0px;
              }
            }

            i{
              color: #b4b4b4;
              margin: 0;
              margin-right: 5px;
              margin-bottom: 2px;
            }

            i.check{
              color: #b4b4b4;
            }
          }

          .bash-body{
            padding: 1rem;
            overflow-x: auto;
            display: flex;
            flex-direction: column;
            font-family: 'Fira Code', monospace;

            p{
              color: white;
              font-family: monospace;
              font-size: 12.5px;
              margin-bottom: 3px;
              font-weight: 500;
              display: flex;
              flex-direction: row;
              gap:4.5px;
              white-space: nowrap;
              display: block;
              //font-family: 'Consolas', monospace;
              font-family: 'Fira Code', monospace;
              text-transform: none;
              content: none;

              code{
                color:#999999;
                font-size: 12.5px;

                &.white{
                  color: white;
                }

                &.green{
                  color:#00a67d;
                }
              }

              .orange{
                color: #e9950c;
                padding: 0px;
                background-color: transparent;
                font-weight: 400;
                font-size: 12.5px;
                font-family: 'Fira Code', monospace;
              }

              .blue{
                padding: 0px;
                background-color: transparent;
                color: #2e95d3;
                font-weight: 400;
                font-size: 12.5px;
                font-family: 'Fira Code', monospace;
              }

              .red{
                padding: 0px;
                background-color: transparent;
                color: #f22c3d;
                font-weight: 400;
                font-size: 12.5px;
                font-family: 'Fira Code', monospace;
              }

              .green{
                padding: 0px;
                background-color: transparent;
                color:#00a67d;
                font-weight: 400;
                font-size: 12.5px;
                font-family: 'Fira Code', monospace;
              }

              .grey{
                font-size: 12.5px;
                padding: 0px;
                background-color: transparent;
                color:#999999;
                font-weight: 400;
                font-family: 'Fira Code', monospace;
              }
            }

            .one-space{
              margin-left: 15px;
            }

            .two-spaces{
              margin-left: 30px;
            }

            .three-spaces{
              margin-left: 45px;
            }

            .four-spaces{
              margin-left: 60px;
            }

            .five-spaces{
              margin-left: 75px;
            }
          }
        }

        ::-webkit-scrollbar {
          height: 12px; /* Altura de la barra de desplazamiento horizontal */
        }
        
        ::-webkit-scrollbar-track {
          background: #353740; /* Color del track (fondo) */
        }
        
        ::-webkit-scrollbar-thumb {
          background: #888; /* Color de la barra de desplazamiento */
        }
        
        ::-webkit-scrollbar-thumb:hover {
          background: #555; /* Color de la barra de desplazamiento al pasar el mouse */
        }

        h3{
          font-family: system-ui;
          font-weight: 700;
          color: #1e40af;
          font-size: 1.55rem;
          line-height: 2.5rem;
          margin-bottom: 0.8rem;
          margin-top: 30px;
        }

        h4{
          font-family: system-ui;
          font-weight: 600;
          color: black;
          font-size: 1.1rem;
          line-height: 2.5rem;
          margin-bottom: 0.4rem;
          margin-top: 15px;
        }
      }
      
      .footer{
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;

        span{
          color: #1e40af;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding-top: 0.125rem;
          padding-bottom: 0.125rem;
          padding-left: 0.625rem;
          padding-right: 0.625rem;
          background-color: #dbeafe;
          border-radius: 0.25rem;
          font-family: system-ui;
        }
      }
    }
  }
}

@media (min-width: 200px) {
  .blog-section{
    .blog-container {
      width: 100%;

      .blog-header{
        flex-direction: column;
        align-items: center;

        .blog-search-bar-container{
          width: 100%;

          .blog-search-bar{
            width: 100%;
          }
        }

        .blog-category-buttons-container{
          margin-top: 1.5rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }

        .blog-category-buttons-container> :nth-child(3) {
          grid-column: 1 / -1; /* Hace que el tercer elemento ocupe todo el ancho */
          justify-self: center; /* Centra el elemento en su posición */
        }
      }

      .blog-cards-section{
        .blog-cards-container{
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 5rem;
        }
      }
    }
  }

  .article-section{
    .article-container {
      width: 100%;

      .article{
        .article-div{
          .step{
            .two-column-step{
              display: flex;
              flex-direction: column-reverse;

              .step-line{
                min-width: 325px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .blog-section{
    .blog-container {
      max-width: 640px;
    }
  }

  .article-section{
    .article-container {
      max-width: 640px;

      .article{
        .article-div{
          .step{
            .two-column-step{
              display: flex;
              flex-direction: column-reverse;

              .step-line{
                min-width: 550px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .blog-section{
    .blog-container {
      max-width: 768px;

      .blog-header{
        flex-direction: row;
        align-items: normal;

        .blog-search-bar-container{
          width: auto;

          .blog-search-bar{
            width: 256px;
          }
        }

        .blog-category-buttons-container{
          margin-top: 0px;
        }
      }

      .blog-cards-section{
        .blog-cards-container{
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  .article-section{
    .article-container {
      max-width: 768px;
    }
  }
}

@media (min-width: 1024px) {
  .blog-section{
    .blog-container {
      max-width: 1024px;

      .blog-header{
        .blog-category-buttons-container{
          grid-template-columns: repeat(3, 1fr);
        }

        .blog-category-buttons-container> :nth-child(3) {
          grid-column: auto;
          justify-self: auto;
        }
      }

      .blog-cards-section{
        .blog-cards-container{
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }

  .article-section{
    .article-container {
      max-width: 1024px;
    }
  }
}

@media (min-width: 1280px) {
  .blog-section{
    .blog-container {
      max-width: 1280px;
    }
  }

  .article-section{
    .article-container {
      max-width: 1280px;

      .article{
        .article-div{
          .step{
            .two-column-step{
              display: flex;
              flex-direction: row;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1536px) {
  .blog-section{
    .blog-container {
      max-width: 1536px;
    }
  }

  .article-section{
    .article-container {
      max-width: 1536px;
    }
  }
}

@media (max-width: 1023px) {
  .article-section{
    .article-container{
      .article{
        .article-div{
          .step{
            .blog-milvus-table-container{
              transform: scale(0.8);

              button{
                &.fields{
                  padding: 1.2rem 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .article-section{
    .article-container{
      .article{
        .article-div{
          .step{
            .blog-milvus-table-container{
              transform: scale(0.65);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .article-section .article-container .article{
    padding-left: 20px;
    padding-right: 15px;

    .article-div{
      padding-left: 0px;
      padding-right: 0px;

      .bash{
        .bash-body{
          p{
            display: inline-block;
            margin-right: 5px;
          }
        }
      }

      .step{
        .mobile-relative{
          position: relative;
        }
        .blog-milvus-table-container{
          position: absolute;
          top: -450px; /* Evita el espacio superior */
          left: 0;
          right: 0;
          bottom: 0;
          transform: scale(0.4);
          margin-top: 30px;
          margin-bottom: 45px;
        }
      }

      .custom-bullets{
        &.mb-275{
          margin-bottom: 340px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .blog-section{
    .blog-container{
      .blog-footer{
        display: flex;
      }
    }
  }

  .article-section{
    .article-container{
      .article{
        .article-div{
          .step{
            .blog-table{
              display: flex;
            }

            .privacy-policy-table-container{
              &.blog{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .article-section{
    .article-container{
      .article{
        .article-div{
          .step{
            .blog-milvus-table-container{
              .center-line{
                width: 850px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1535px) {
  .article-section{
    .article-container{
      .article{
        .article-div{
          .step{
            .blog-alternative-table-container{
              display: flex;
              flex-direction: column;
              gap: 25px;
              justify-content: center;
              align-items: center;
              
              .blog-alternative-table{
                max-width: auto;
                min-width: auto;
    
                .overflow-x-auto{
                  overflow-x: auto;
    
                  table{
                    tbody{
                      tr{
                        td{
                          white-space: inherit;
                        }
                      }
                    }
                  }
                }
              }

              .blog-alternative-table:last-child{
                margin-bottom: 2.5rem;
              }
            }

            .blog-milvus-table-container{
              .center-line{
                width: 750px;
              }
            }
          }
        }
      }
    }
  }
}



@media (max-width: 460px) {
  .article-section{
    .article-container{
      .article{
        .article-div{
          .step{
            .blog-alternative-table-container{   
              align-items: normal;           
              .blog-alternative-table{  
                padding-left: 1.5rem;
                padding-right: 1.5rem;  
                .overflow-x-auto{
                  overflow-x: auto;
    
                  table{
                    min-width: 100%;
                    text-indent: 0;
                    border-color: inherit;
                    border-collapse: collapse;

                    p{
                      white-space: inherit;
                    }
    
                    thead{
                      tr{
                        th{
                          color: #6b7280;
                          letter-spacing: 0.05em;
                          text-transform: uppercase;
                          font-weight: 500;
                          font-size: 0.75rem;
                          line-height: 1rem;
                          text-align: left;
                          padding-top: 0.75rem;
                          padding-bottom: 0.75rem;
                          padding-left: 0.5rem;
                          padding-right: 0.5rem;
                          font-family: system-ui;
                          white-space: inherit;
                        }

                        th:last-child{
                          padding-right: 0rem;
                        }

                        th:first-child{
                          padding-left: 0rem;
                        }
                      }
                    }
                    
                    tbody{
                      tr{
                        border-top: 1px solid #e5e7eb;
    
                        &.nb{
                          border-top: none;
                        }
    
                        td{
                          padding-top: 1rem;
                          padding-bottom: 1rem;
                          padding-left: 0.5rem;
                          padding-right: 0.5rem;
                          
                        }

                        td:last-child{
                          padding-right: 0rem;
                          
                        }

                        td:first-child{
                          padding-left: 0rem;
                          max-width: 135px;
                        }
                      }
                    }
                  }
                }
              }

              .blog-alternative-table:last-child{
                margin-bottom: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
}

/****************END-BLOG***************/

/****************SPINNER****************/

.containerSpinnerGeneral{
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;

  .segmentSpinner{
      flex-grow: 1;
  }

  overflow: hidden;
}

.containerSpinnerSecondary{
  height: 200px;
  width: 100%;

  &.contact{
    height: 312.8px;
  }

  &.chat{
    height: 125px;

    .ui.dimmer{
      .content{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 0;

        .ui.text.loader{
          margin:0;
          font-size: 1.4rem !important;
          position: relative;
          margin-top: 60px;
        }
      }
    }
  }

  .ui.dimmer{
    background: none;
    border: none;
    flex-direction: row;
    position: inherit;

    .content{
      margin-top: 100px;
      .ui.text.loader{
        margin-left: 100px;
        padding-top: 45px;
      }

      .ui.loader{
        color: #E5AC69 !important; 
        font-weight: 500;
        font-size: 1.8rem !important;
        position: inherit;
      }

      .ui.loader:before{
        border-color: #E5AC69 !important;
        left: 52%;
      }

      .ui.loader:after{
        left: 52%;
      }
    }
  }
}

@media (max-width: 400px) {
  .containerSpinnerSecondary{
    &.contact{
      height: 470px;
    }
  }
}


/**************END-SPINNER**************/

/****************EFFECTS****************/

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/**************END-EFFECTS**************/

/***************SNACKBAR****************/

.toastSuccess .mdc-snackbar__surface{
  background: #26b55d; 
}

.errorToast .mdc-snackbar__surface{
  background: #D36060; 
}

.mdc-snackbar{
  top: 100px;
  position: fixed;
  bottom: auto !important;
}

.mdc-snackbar__label{
  font-weight: 700 !important;
}

.mdc-snackbar__action{
  background: none;
  color: #fff !important;
  border: none;
}

/*************END-SNACKBAR**************/

/****************CURSOR*****************/

.cursor{
  cursor: pointer;
}

/***************END-CURSOR**************/

/****************MODAL*****************/

.ReactModal__Body--open{
  overflow: hidden;
}

.ReactModal__Overlay{
  background: rgba(#333333, 0.85) !important;
  display: flex !important;
  z-index: 4;
  cursor: zoom-out;
}

.newsletter-overlay{
  background: rgba(#000000, 0.5) !important;
  z-index: 4;
  position: fixed;
  inset: 0px;
  cursor: auto;
}

.discover-blog-card-overlay{
  background: rgba(#000000, 0.3) !important;
  z-index: 4;
  position: fixed;
  inset: 0px;
  cursor: auto;
}

.ReactModal__Content{
  border-radius: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 10px 50px 5px rgba(#f1f1f1, 0.25);
  margin: auto;
  display: flex;
  flex-direction: column;

  .titleModal{
      color: $primary;
      font-size: 1.7em;
  }

  .subTitleModal{
      color: $primary;
      font-size: 1.2em;
  }
}

.generic-modal{
  position: relative;
  outline: none;

  &.container-modal{
      padding: 0 !important;
      height: auto;
      width: 750px;

      &.newsletter{
        width: 435px;
        border-radius: 1rem;

        .newsletter-card{
          border-radius: 1rem;
          padding: 1.8rem;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .closeModal {
            background-color: transparent;
            opacity: .6;
            position: absolute;
            right: 10px;
            text-align: center;
            top: 10px;
            width: 30px;
            z-index: 9999;
          }
  
          h1{
            color: #26266b;
            font-weight: 700;
            font-size: 1.7rem;
            line-height: 2.5rem;
            font-family: system-ui;
            margin-bottom: 15px;
          }

          p{
            font-family: system-ui;
            margin-bottom: 0px;
            font-size: 1.1rem;
            color: #4b5563;
          }

          .subscribe{
            margin-top: 5px;
            text-align: center;
          }

          input{
            font-family: system-ui;
            margin-top: 22px;
            padding: 0.60rem;
            padding-left: 1rem;
            outline: none;
            border: 1px solid #a0a0a05e;
            border-radius: 0.5rem;
            font-size: 1.1rem;
          }

          input::placeholder{
            color: #a0a0a0;
          }

          input:focus{
            border: 2.2px solid rgb(29, 78, 216);
            transition: none;
          }
  
          input::before{
            outline: none;
          }

          button{
            margin-top: 16px;
            margin-right: 0px;
            border-radius: 0.5rem;
            background-color: #26266b;
            border: 0px solid#26266b;
            font-family: system-ui;
            font-size: 1.1rem;
            color: white;
          }
        }
      }

      &.discover-blog{
        animation: fadeIn 0.5s ease-out;
        box-shadow: 0px 10px 50px 5px rgba(#f1f1f1, 0.15);
        width: 450px;
        border-radius: 1rem;

        .discover-blog-card{
          border-radius: 1rem;
          padding: 2rem;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .closeModal {
            background-color: transparent;
            opacity: .6;
            position: absolute;
            right: 10px;
            text-align: center;
            top: 10px;
            width: 30px;
            z-index: 9999;
            cursor: pointer;
            color: red;
          }

          .header-image{
            height: 4.5rem;
            width: 4.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 9999px;
            background-color: #dbeafe;
            margin-bottom: 1.5rem;
            margin-left: auto;
            margin-right: auto;

            img{
              width: 2.2rem;
            }
          }

          h1{
            color: #26266b;
            font-weight: 700;
            font-size: 1.7rem;
            line-height: 2.5rem;
            font-family: system-ui;
            margin-bottom: 1rem;
            text-align: center;
          }

          p{
            font-family: system-ui;
            margin-bottom: 1.5rem;
            color: #4b5563;
            font-size: 1.165rem;
            text-align: center;
          }

          a{
            .explore{
              width: 100%;
            }
          }

          button{
            margin-top: 16px;
            margin-right: 0px;
            border-radius: 0.5rem;
            background-color: #26266b;
            border: 0px solid#26266b;
            font-family: system-ui;
            font-size: 1.1rem;
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;

            img{
              width: 1.3rem;
              margin-top: 2px;
            }
          }

          label{
            font-size: 0.9rem;
            color: $button-error-hover;
            margin-top: 10px;
            margin-bottom: 0px;
            margin-left: 5px;
          }

          .explore{
            transition: transform 0.3s ease;
          }

          .explore:hover{
            transform: scale(1.05);
            background-color: #1d4ed8;
          }

          .later{
            background-color: transparent;
            border: 0px;
            color: #4b5563;
            padding-top: 0px;
            padding-bottom: 0px;
          }

          .later:hover{
            color:#26266b;
          }

          input{
            font-family: system-ui;
            padding: 0.60rem;
            padding-left: 1rem;
            outline: none;
            border: 1px solid #a0a0a05e;
            border-radius: 0.5rem;
            font-size: 1.1rem;
          }

          input::placeholder{
            color: #a0a0a0;
          }

          input:focus{
            border: 2.2px solid rgb(29, 78, 216);
            transition: none;
          }
  
          input::before{
            outline: none;
          }
        }
      }
      
      .closeModal {
        background-color: transparent;
        opacity: .6;
        position: fixed;
        right: 25px;
        text-align: center;
        top: 25px;
        width: 30px;
        z-index: 9999;
      }

      .service-card{
        display: flex;
        flex-direction: row;
        border-top: 6px solid $primary;
        border-radius: 10px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin: 0px;
        cursor: zoom-out;
        padding: 10px;

        .service-card-content{
          width: 500px;
          padding-bottom: 10px;

          .service-card-header{
            padding: 25px;
            padding-right: 0px;
            padding-bottom: 10px;
            margin-bottom: 0px;
            border-bottom: none;

            .service-card-title{
              font-size: 24px;
              color: black;
              font-weight: 600;
              text-align: left;
              margin: 0px;
            }
          }

          .service-card-body{
            display: flex;
            flex-direction: column;
            font-size: 16px;
            padding: 1.25rem;
            padding-right: 0px;

            .sub-body{
              margin-bottom: 20px;
              padding-left: 10px;
              display: flex;
              flex-direction: column;

              h1{
                font-size: 19px;
                color: $primary;
                margin: 0px;
                margin-bottom: 10px;
              }

              p{
                font-size: 17px;
                color: #676767;
                margin-bottom: 0px;
              }
            }

            .sub-body:last-child{
              margin-bottom: 0px;
            }
          }
        }

        .service-card-image{        
          width: 250px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          img{
            width: 160px;
          }
        }
      }
  }
}

@media only screen and (max-width: 767px) {
  .generic-modal{
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: 100%;

    .service-card{
      .service-card-content{
        width: 100% !important;
      }

      .service-card-image{        
        display: none !important;   
      }
    }
  }
}

/**************END-MODAL***************/


@media (max-width: 767px) {
  .not-display-on-mobile{
    display: none !important;
  }
}

/*************CHAT-BUBBLE***************/

.chat-bubble-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 4;

  .chat-bubble {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #26266b;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    margin: 0;

    span{
      margin-top: 2.5px;
      margin-left: 2.5px;
    }
  }
  
  .chat-bubble:hover {
    transform: scale(1.05);
    background-color: #3d3d97;
  }
}

.chat-window {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 320px;
  background-color: transparent;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  font-family: 'Coda', sans-serif;

  &.new-width{
    width: 375px;
  }
}

.chat-header {
  padding: 16px 20px;
  background-color: #26266b;
  color: white;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }

  .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 28px;
    cursor: pointer;
    padding: 0px;
  }

  .close-button:disabled{
    cursor: not-allowed;
  }
}

.chat-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  &.pr{
    padding-right: 8px;
  }

  .chat-option {
    padding: 12px 16px;
    background-color: transparent;
    border: 1px solid #E5AC69;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    color: #676767;
    font-family: 'Coda', sans-serif;
  }
  
  .chat-option:hover {
    background-color: #e5ac6933;
    color: #E5AC69;
    border-color: #E5AC69;
  }
}

.back-button {
  background-color: rgb(38, 38, 107);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  cursor: pointer;
  margin-bottom: 2rem;
  width: fit-content;
  margin-bottom: 20px;
  border: none;

  img{
    height: 20px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    color: #26266b;
  }

  label{
    color: white;
    font-family: system-ui;
    font-size: 1.125rem;
    margin-bottom: 2px;
    cursor: pointer;
  }
}

.back-button:hover {
  background-color: #3d3d97;;
}

.back-button:disabled {
  background-color: #6262aa;
  cursor: not-allowed;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 400px;
  overflow-y: auto;

  &.pr{
    padding-right: 8px;
  }
}

.calendar-container::-webkit-scrollbar {
  width: 8px;
}

.calendar-container::-webkit-scrollbar-track {
  background: rgba(38, 38, 107, 0.1);
  border-radius: 4px;
}

.calendar-container::-webkit-scrollbar-thumb {
  background-color: rgba(38, 38, 107, 0.2);
  border-radius: 4px;
}

.date-slot {
  border-bottom: 1px solid #E2E2E2;
  padding-bottom: 16px;
}

.date-slot h4 {
  margin: 0 0 12px 0;
  color: #676767;
  font-weight: 500;
  font-size: 15px;
}

.time-slots {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .time-slot:last-child{
    margin-right: 0px;
  }
}

.time-slot {
  min-width: 155px;
  padding: 8px 12px;
  background-color: rgba(213,213,213, 0.1);
  border: 1px solid #E5AC69;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #676767;
  font-family: 'Coda', sans-serif;
  font-size: 13.5px;
  margin-right: 5px;
}

.time-slot:hover {
  background-color: #e5ac6933;
  color: #E5AC69;
  border-color: #E5AC69;
}

.time-slot.selected {
  background-color: #26266b;
  color: white;
  border-color: #26266b;
}

.time-slot.selected:hover {
  background-color: #3d3d97;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 300px;
  overflow-y: auto;
  padding-right: 8px;
}

.message {
  padding: 12px 16px;
  border-radius: 12px;
  max-width: 80%;
  font-family: 'Coda', sans-serif;
}

.message.system {
  background-color: rgba(213,213,213, 0.1);
  align-self: flex-start;
  color: #676767;
  border: 1px solid #E2E2E2;
}

.message.user {
  background-color: #26266b;
  color: white;
  align-self: flex-end;
}

.chat-input-container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.chat-input {
  flex: 1;
  padding: 12px;
  background: rgba(213,213,213, 0.1);
  color: #676767;
  font-size: 16px;
  border: 1px solid #E2E2E2;
  border-color: #E5AC69;
  border-radius: 8px;
  font-family: 'Coda', sans-serif;
}

.chat-input:focus {
  box-shadow: none;
  border-bottom: 2px solid #26266b;
  color: #26266b;
  outline: none;
}

.send-button {
  padding: 12px 20px;
  background-color: #26266b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-family: 'Coda', sans-serif;
}

.send-button:hover {
  background-color: #3d3d97;
}

.booking-form {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E2E2E2;

  h4 {
    margin: 0 0 8px 0;
    color: #676767;
    font-weight: 500;
    font-family: 'Coda', sans-serif;
    font-size: 15px;
  }

  .labelIncompleteData{
    font-size: 0.87rem;
    color: $button-error-hover;
    margin-bottom: 12px;
    margin-left: 3px;
  }
}

.booking-form p {
  color: #26266b;
  margin: 0 0 16px 0;
  font-size: 14px;
  font-family: 'Coda', sans-serif;
}

.email-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #E5AC69;
  border-radius: 8px;
  font-size: 14px;
  color: #676767;
  font-family: 'Coda', sans-serif;
  background-color: rgba(213,213,213, 0.1);

  &.mb10{
    margin-bottom: 6px;
  }
}

.email-input:focus {
  outline: none;
  border-color: #26266b;
  box-shadow: 0 0 0 2px rgba(38, 38, 107, 0.1);
}

.booking-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.cancel-button, .book-button {
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Coda', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background-color: rgba(213,213,213, 0.1);
  border: 1px solid #E5AC69;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #676767;
}

.cancel-button:hover {
  background-color: #e5ac6933;
  color: #E5AC69;
  border-color: #E5AC69;
}

.cancel-button:disabled {
  background-color: #ddba9233;
  cursor: not-allowed;
}

.book-button {
  background-color: #26266b;
  border: none;
  color: white;
}

.book-button:hover {
  background-color: #3d3d97;
}

.book-button:disabled {
  background-color: #6262aa;
  cursor: not-allowed;
}

.booking-success {
  text-align: center;
  padding: 24px;
  background-color: rgba(38, 38, 107, 0.1);
  border-radius: 8px;
  color: #26266b;
}

.booking-success p {
  margin: 8px 0;
  font-family: 'Coda', sans-serif;
}

@media (max-width: 450px) {
  .chat-window{
    &.new-width{
      width: 340px;
    }

    .back-button{
      padding:0.20rem 0.70rem 0.20rem 0.45rem;
      margin-bottom: 15px;
      
      img{
        height: 19px;
      }

      label{
        font-size: 1rem;
      }
    }

    .calendar-container{
      max-height: 325px;
    }

    .date-slot{
      h4{
        font-size: 14.5px;
      }

      .time-slots{
        row-gap: 9px;
        .time-slot{
          max-width: 138px;
          min-width: 138px;
          font-size: 13.2px;
          padding: 6px 6px 3.5px 6px;
          margin-right: 0px;
        }
      }
    }
  }
}

/***********END-CHAT-BUBBLE*************/

/**@coowise/cookies-preferences-package**/

@media (max-width: 450px) {
  .cookie-consent-banner.is-active.web-coowise{
    .cta-actions{
      button:last-child{
        margin-left: 5px;
        margin-right: 0px;
      }
    }
  }
}

/*END-@coowise/cookies-preferences-package*/